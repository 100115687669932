<template>
  <splitpanes class="default-theme dth" @resize="paneSize = $event[0].size">
    <pane :size="paneSize">
      <div class="left">
        <el-input
          clearable
          v-model="query_key"
          :placeholder="$t('map.qingshuruchaxunguanjianzi')"
          @input="onQueryChanged"
        />
        <el-tree-v2
          ref="treeRef"
          :data="treeData"
          :height="600"
          :props="propoption"
          show-checkbox
          :filter-method="filterMethod"
          @node-contextmenu="rightClick"
          ><template #default="{ node, data }">
            <span class="prefix"><img :src="data.icon" alt="" /></span>
            <span @dblclick="dblclickTree(node, data)">{{ node.label }}</span>
          </template></el-tree-v2
        >
      </div>
    </pane>
    <pane :size="100 - paneSize">
      <div class="cc" ref="cc">
        <div class="op-box">
          <div class="op-it" @click="change_video(4)">4</div>
          <div class="op-it" @click="change_video(9)">9</div>
          <div class="op-it" @click="change_video(16)">16</div>
        </div>
        <div class="box" v-for="i in boxes" :key="i">
          <GpsVideo :ref="'video' + i" :width="ww" :height="hh"></GpsVideo>
        </div>
      </div>
    </pane>
  </splitpanes>
</template>

<script>
import { fa } from "element-plus/es/locale";
import { Microphone } from "@element-plus/icons-vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import GpsVideo from "../components/GpsVideo.vue";
import GpsSocket from "../utils/GpsSocket";

export default {
  components: { Splitpanes, Pane, GpsVideo },
  data() {
    return {
      ay_player: [],
      boxes: 4,
      paneSize: 20,
      csszProps: {
        children: "children",
        label: "name",
      },
      curr_tree_node: { id: 0 },
      propoption: {
        icon: "icon",
        value: "gps_id",
        label: "nodeName",
        children: "children",
      },
      treeData: [],
      query_key: "",
      cheigth: 600,
      ww: 300,
      hh: 300,
    };
  },
  mounted() {
    setTimeout(() => {
      this.calculateBoxes();
    }, 1000);

    // const cc = this.$refs.cc;
    // const ccWidth = cc.offsetWidth;
    // const ccHeight = cc.offsetHeight;
    // console.log("ccWidth: " + ccWidth);
    // console.log("ccHeight: " + ccHeight);

    this.$api.tree_deptTree_Tree({}).then((res) => {
      this.treeData = res.data.resultData;
    });
  },
  methods: {
    change_video(num) {
      this.boxes = num;
      this.$nextTick(() => {
        this.calculateBoxes();
      });
    },
    dblclickTree(node, data) {
      let dPara = data.dPara;
      let that = this;
      if (dPara != "") {
        let ay = dPara.split(",");
        let sn = data.devID;
        let sn_org = data.devID;
        let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
        let video_port = this.$store.getters.orderPort; //"9500";
        let channel_id = 1;

        sn = this.leftZeroPadding(sn, 11);

        ay.forEach((it, idx) => {
          channel_id = idx + 1;
          GpsSocket.sendMsg("order", {
            deviceid: sn_org,
            order: "9101",
            content: video_ip + "," + video_port + ",0," + channel_id + ",0,1",
          });
        });

        let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
        let video_port_play = this.$store.getters.videoPort; //4022;
        // let url =
        //   "https://" +
        //   video_ip_play +
        //   ":" +
        //   video_port_play +
        //   "/flv?port=10077&app=live&stream=" +
        //   sn +
        //   "_channel_" +
        //   channel_id;
        const get_video_url = (
          video_ip_play,
          video_port_play,
          sn,
          channel_id
        ) => {
          return (
            "https://" +
            video_ip_play +
            ":" +
            video_port_play +
            "/flv?port=10077&app=live&stream=" +
            sn +
            "_channel_" +
            channel_id
          );
        };

        setTimeout(() => {
          ay.forEach((it, idx) => {
            let url = get_video_url(
              video_ip_play,
              video_port_play,
              sn,
              idx + 1
            );
            this.ay_player.push(url);
            let player_idx = this.ay_player.length % this.boxes;
            if (player_idx == 0) {
              player_idx = this.boxes;
            }
            this.$refs["video" + player_idx][0].playUrl(url);
          });
        }, 3000);
      }
      // console.log(node, data);
      // var video_ip_array = "{$Think.config.Video_Server_Ip}";

      //https://test.cnms365.com:4022/flv?port=10077&app=live&stream=18124567850_channel_1
      //https://test.cnms365.com:4022/flv?port=10077&app=live&stream=18124567850_channel_1
      //https://test.cnms365.com:4022/flv?port=10077&app=live&stream=18124567850_channel_1
      //https://test.cnms365.com:4022/flv?port=10077&app=live&stream=18124567850_channel_1&r=0.8337534299061771

      // setTimeout(() => {
      //   console.log(url);
      //   console.log(this.$refs["video1"]);
      //   this.$refs["video1"][0].playUrl(url);
      // }, 5000);
    },
    calculateBoxes() {
      const cc = this.$refs.cc;
      const ccWidth = cc.offsetWidth;
      console.log("ccWidth: " + ccWidth);
      const ccHeight = cc.offsetHeight;
      console.log("ccHeight: " + ccHeight);

      // 根据容器的宽高和网格布局的列数计算每个区域的宽和高
      let width, height;
      // if (ccWidth > ccHeight) {
      //   width = Math.floor(ccWidth / Math.sqrt(this.boxes));
      //   height = Math.floor(ccHeight / (Math.sqrt(this.boxes) - 1));
      // } else {
      //   width = Math.floor(ccWidth / (Math.sqrt(this.boxes) - 1));
      //   height = Math.floor(ccHeight / Math.sqrt(this.boxes));
      // }
      width = Math.floor(ccWidth / Math.sqrt(this.boxes));
      height = Math.floor(ccHeight / Math.sqrt(this.boxes));
      this.ww = width;
      this.hh = height;

      // 设置每个区域的宽和高
      const boxes = cc.querySelectorAll(".box");
      boxes.forEach((box) => {
        box.style.width = `${width}px`;
        box.style.height = `${height}px`;
      });
    },
    filterMethod(query, node) {
      return node.nodeName.includes(query);
    },
    leftZeroPadding(number, length) {
      return String(number).padStart(length, "0");
    },
    onQueryChanged(query) {
      this.$refs.treeRef.filter(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.cc {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-content: space-around;
  height: 100%;
  position: relative;
  .op-box {
    z-index: 1000000;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    color: #ddd;
    .op-it {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.box {
  box-sizing: border-box;
  background: #c41717;
  border: 1px solid #ddd;
  float: left;
}
</style>
